<template>
  <hcc-modal
    name="edit-location"
    :title="
      edit
        ? $t('campaigns.data-bank.location.edit')
        : $t('campaigns.data-bank.location.add')
    "
    @confirm="updateLocation"
    @opened="focusRefs"
    @closed="clearData"
    :confirmDisableButton="!saveEnable"
  >
    <div class="location-modal">
      <hcc-input
        class="location-modal__input"
        ref="locationName"
        :label="$t('common.name')"
        :placeholder="$t('common.name')"
        v-model.trim="locationName"
        @keyup.enter="goNext"
      />
      <span class="location-modal__label">{{
        $t("campaigns.data-bank.location.tab")
      }}</span>
      <gmap-autocomplete
        class="location-modal__search"
        :placeholder="placeholder"
        :selectFirstOnEnter="true"
        @place_changed="changeByAddress"
      />
      <gmap-map
        :center="locationData"
        :zoom="16"
        @click="changeLocation"
        class="location-modal__map"
      >
        <gmap-marker
          :position="locationData"
          @click="changeLocation($event)"
          @dragend="changeLocation($event)"
          :draggable="true"
          :clickable="true"
        />
      </gmap-map>
      <span class="location-modal__text">
        {{ $t("campaigns.data-bank.location.label") }}
      </span>
    </div>
  </hcc-modal>
</template>

<script>
import EventBus from '@/eventBus';
import { UPDATE_DATA_BANK, CREATE_DATA_BANK } from '@/eventTypes';
import getLocation from '@/utils/getLocation';
import HccInput from '@/components/shared/HccInput/index.vue';

export default {
  props: {
    location: {
      required: true,
      type: Object,
    },
    order: {
      type: Number,
      required: true,
    },
    edit: {
      type: Boolean,
    },
  },
  components: {
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput,
  },
  data() {
    return {
      locationName: '',
      locationData: {},
      defaultCord: {
        lat: 20.677467,
        lng: -103.347939,
      },
      selectedLocation: '',
    };
  },
  computed: {
    saveEnable() {
      return this.locationName !== '' && this.locationData.lat !== this.defaultCord.lat && this.locationData.lng !== this.defaultCord.lng;
    },
    apiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
    placeholder() {
      const selected = this.selectedLocation.length > 0;

      if (this.edit) {
        if (selected) {
          return this.selectedLocation;
        }
        return this.getLocationFromLatLng(this.locationData.lat, this.locationData.lng);
      }

      return selected ? this.selectedLocation
        : this.$t('campaigns.data-bank.location.search');
    },
  },
  watch: {
    location(newVal) {
      this.locationData = this.setLocation(newVal);
      this.getLocationFromLatLng(this.locationData.lat, this.locationData.lng);
    },
  },
  mounted() {
    this.locationData = this.setLocation(this.location);
  },
  methods: {
    changeLocation(event) {
      this.locationData = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.getLocationFromLatLng(this.locationData.lat, this.locationData.lng);
    },
    changeByAddress(address) {
      if (address && address.geometry && address.geometry.location) {
        this.locationData = {
          lat: address.geometry.location.lat(),
          lng: address.geometry.location.lng(),
        };
      }
    },
    isEmpty(ln) {
      return Object.keys(ln).length === 0;
    },
    setLocation(ln) {
      if (this.isEmpty(ln)) {
        this.locationName = '';
        return this.defaultCord;
      }

      this.locationName = ln.location.name;
      return {
        lat: ln.location.lat,
        lng: ln.location.lng,
      };
    },
    updateLocation() {
      if (this.isEmpty(this.location)) {
        this.create();
      } else {
        this.update();
      }
    },
    create() {
      const data = {
        location: {
          name: this.locationName,
          ...this.locationData,
        },
        order: this.order,
        type: 'location',
      };
      EventBus.$emit(CREATE_DATA_BANK, data);
    },
    update() {
      this.selectedLocation = '';
      const remove = false;
      const data = {
        id: this.location.id,
        location: {
          name: this.locationName,
          ...this.locationData,
        },
      };

      EventBus.$emit(UPDATE_DATA_BANK, { data, remove, type: 'location' });
    },
    focusRefs() {
      this.$refs.locationName.focus();
    },
    getLocationFromLatLng(lat, lng) {
      getLocation(lat, lng, this.apiKey)
        .then((result) => {
          this.selectedLocation = result;
        });
    },
    clearData() {
      this.selectedLocation = '';
      this.locationData = {};
      this.locationData = this.setLocation(this.location);
    },
  },
};

</script>

import Vue from 'vue';
import Axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, Axios);

export default async function getLocation(lat, lng, apiKey) {
  const result = await Axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`)
    .then((response) => {
      const { data } = response;
      let address = '';

      if (data && data.status === 'OK' && data.results.length > 0) {
        address = data.results[0].formatted_address;
      }
      return address;
    });

  return result;
}
